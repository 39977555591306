import { IconInstagram, IconTwitter, IconDiscord } from "../../components";

import { useState } from "react";

import logo from "../../assets/img/logo.png";

export function Header({ menu }) {
  const [isNavOpen, setIsNavOpen] = useState(false);

  console.log("here is menu");
  console.log(menu);

  const menuItems = menu.map((item) => (
    <li key={item.id}>
      <a href={item.to} className={`text-xl`}>
        {item.title}
      </a>
    </li>
  ));

  console.log(menuItems);

  return (
    <div className="flex items-center justify-between p-8 bg-dark text-light">
      <a href="/">
        <img
          src={logo}
          alt="Women and Weapons"
          height="80"
          width="300"
          className="sm:w-24 object-left lg:w-auto max-w-sm h-24 object-contain md:object-center"
        />
      </a>

      <nav>
        <section className="MOBILE-MENU flex lg:hidden">
          <div
            className="HAMBURGER-ICON space-y-2"
            onClick={() => setIsNavOpen((prev) => !prev)}
          >
            <span className="block h-0.5 w-8 animate-pulse bg-light"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-light"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-light"></span>
          </div>

          <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
            <div
              className="absolute top-0 right-0 px-8 py-8"
              onClick={() => setIsNavOpen(false)}
            >
              <svg
                className="h-8 w-8 text-light"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <ul className="flex flex-col items-center justify-between min-h-[250px]">
              {menuItems}
              <li>
                <div className={`flex gap-8`}>
                  <a
                    href="https://twitter.com/WomenandWeapons"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconTwitter />
                  </a>
                  <a
                    href="https://discord.gg/womenandweapons"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconDiscord />
                  </a>
                  <a
                    href="https://www.instagram.com/nftwomenandweapons/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconInstagram />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </section>

        <ul className="DESKTOP-MENU hidden space-x-8 lg:flex lg:items-center">
          {menuItems}
          <li>
            <div className={`flex gap-8`}>
              <a
                href="https://twitter.com/WomenandWeapons"
                target="_blank"
                rel="noreferrer"
              >
                <IconTwitter />
              </a>
              <a
                href="https://discord.gg/womenandweapons"
                target="_blank"
                rel="noreferrer"
              >
                <IconDiscord />
              </a>
              <a
                href="https://www.instagram.com/nftwomenandweapons/"
                target="_blank"
                rel="noreferrer"
              >
                <IconInstagram />
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}
