import { Layout, NFTContainer } from "./components";

function App() {
  return (
    <>
      <Layout>
        <NFTContainer />
      </Layout>
    </>
  );
}

export default App;
