import { NFTPlaceholderCard } from "../../components";

export function DisconnectedState({ connectWallet }) {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="bg-dark text-light px-4 py-8 md:p-8 text-center w-screen md:rounded-xl drop-shadow-xl absolute top-[42%] md:w-[600px] md:left-auto left-0 z-[1] flex flex-col justify-center items-center">
        <div className="text-2xl md:text-3xl mb-2">
          Welcome to Women and Weapons
        </div>
        <div className="md:text-lg text-center mb-2">
          A place to view and download your NFTs from our collection!
        </div>
        <button
          onClick={connectWallet}
          className="border-2 border-accent rounded-lg px-8 py-2 text-lg drop-shadow-md flex justify-center items-center mt-4 w-96 hover:bg-accent active:bg-accent focus:outline-none focus:ring focus:ring-accent"
        >
          Connect Wallet
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-8 w-screen max-w-screen-xl opacity-80">
        <NFTPlaceholderCard className="ml-auto mr-auto" />
        <NFTPlaceholderCard className="ml-auto mr-auto" />
        <NFTPlaceholderCard className="ml-auto mr-auto hidden md:block" />
        <NFTPlaceholderCard className="ml-auto mr-auto hidden md:block" />
      </div>
    </div>
  );
}
