import { NFTCardImagePlaceholder } from "../../components";
export function NFTCardImage({
  imgUrls,
  name,
  isImgLoading,
  handleImageLoaded,
}) {
  const loadingClass = isImgLoading ? "block" : "hidden";
  // ${loadingClass}

  const imgStack = imgUrls.map((url, i) => {
    if (i + 1 === imgUrls.length) {
      return (
        <img
          className="rounded-full"
          src={url}
          width={500}
          height={500}
          key={url}
          alt={`NFT asset for ${name}`}
          onLoad={handleImageLoaded}
        />
      );
    } else {
      return (
        <img
          className="rounded-full"
          src={url}
          width={500}
          height={500}
          key={url}
          alt={`NFT asset for ${name}`}
        />
      );
    }
  });

  return (
    <div className="nft-card-image p-4">
      <div className="imagestack">
        <NFTCardImagePlaceholder className={`${loadingClass} rounded-full`} />
        {imgStack}
      </div>
    </div>
  );
}
