import ContentLoader from "react-content-loader";

export function NFTCardImagePlaceholder(props) {
  return (
    <ContentLoader
      uniqueKey="nft-card-img-placeholder"
      speed={1}
      width={500}
      height={500}
      viewBox="0 0 500 500"
      backgroundColor="#262726"
      foregroundColor="#808080"
      foregroundOpacity={0.2}
      {...props}
    >
      <circle cx="250" cy="250" r="500" />
    </ContentLoader>
  );
}
