import { NFTCardImage } from "../../components";
import { downloadNFT, getNFTComponentURLs } from "../../lib/utils";
import { useState, useEffect } from "react";

export function NFTCard({ nft }) {
  const [showBackground, setShowBackground] = useState(true);
  const name = nft.meta.name;
  const attributeArray = nft.meta.attributes;
  const originalImageUrl = nft.meta.content.find(
    (item) => item["@type"] === "IMAGE"
  )?.url;
  const [isImgLoading, setIsImgLoading] = useState(true);

  const [componentUrls, setComponentUrls] = useState([originalImageUrl]);

  useEffect(() => {
    showBackground
      ? setComponentUrls([originalImageUrl])
      : setComponentUrls(getNFTComponentURLs(attributeArray, showBackground));
  }, [showBackground]);

  const handleShowBackground = () => {
    setIsImgLoading(true);
    setShowBackground((curVal) => !curVal);
  };

  const handleImageLoaded = () => {
    setIsImgLoading(false);
  };

  return (
    <div className="flex justify-center items-center w-[300px] h-[470px] m-auto drop-shadow-md">
      <div className="w-[300px] h-[470px] bg-dark p-4 flex flex-1 flex-col items-center text-light drop-shadow-md">
        <NFTCardImage
          imgUrls={componentUrls}
          name={name}
          isImgLoading={isImgLoading}
          handleImageLoaded={handleImageLoaded}
        />
        <div className="flex flex-col flex-1 items-center justify-center gap-4">
          <div className="nft-name">{name}</div>
          <div className="text-xs italic">Download Art:</div>
          <div className="nft-download-buttons flex gap-4">
            <button
              onClick={() =>
                downloadNFT({
                  attributes: attributeArray,
                  tokenID: name,
                  resolution: 600,
                  showBackground,
                })
              }
              className="flex justify-center items-center border px-4 py-1 rounded-3xl"
            >
              SD
            </button>
            <button
              onClick={() =>
                downloadNFT({
                  attributes: attributeArray,
                  tokenID: name,
                  showBackground,
                })
              }
              className="flex justify-center items-center border px-4 py-1 rounded-3xl"
            >
              HD
            </button>
          </div>
          <button
            onClick={handleShowBackground}
            className="flex justify-center items-center border px-4 py-1 rounded-3xl"
          >
            Toggle Background
          </button>
        </div>
      </div>
    </div>
  );
}
