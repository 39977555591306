import { useState, useEffect } from "react";
import { DisconnectedState, NFTCollection } from "../../components";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { providerOptions } from "../../lib/providerOptions";
import { truncateAddress } from "../../lib/utils";

export function NFTContainer() {
  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [account, setAccount] = useState();
  const [signature, setSignature] = useState("");
  const [error, setError] = useState("");
  const [chainId, setChainId] = useState();
  const [network, setNetwork] = useState();
  const [message, setMessage] = useState("");
  const [signedMessage, setSignedMessage] = useState("");
  const [verified, setVerified] = useState();
  const [web3Modal, setWeb3Modal] = useState();
  const [nfts, setNfts] = useState();

  const WxW_ALLOWED_CONTRACT_ADDRESSES = [
    "ETHEREUM:0xa872947e420fc87d0ad56b6c296439f55c3768dc",
    "ETHEREUM:0x338866F8ba75bb9D7a00502E11b099a2636C2C18",
  ];

  useEffect(() => {
    const web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions, // required
    });

    setWeb3Modal(web3Modal);
  }, []);

  useEffect(() => {
    getNftData();
  }, [account]);

  const connectWallet = async () => {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();
      setProvider(provider);
      setLibrary(library);
      if (accounts) setAccount(accounts[0]);
      setChainId(network.chainId);
    } catch (error) {
      setError(error);
    }
  };

  const getNftData = async () => {
    if (!account) return;
    const response = await fetch(
      `https://api.rarible.org/v0.1/items/byOwner?owner=ETHEREUM:${account}`
    );
    const data = await response.json();

    console.log("here is data");
    console.log(data);

    const wxwFilteredNfts = filterNfts(data.items);

    setNfts(wxwFilteredNfts);
  };

  const filterNfts = (nfts) => {
    if (nfts.length == 0) {
      return [];
    }
    return nfts.filter((nft) =>
      WxW_ALLOWED_CONTRACT_ADDRESSES.includes(nft.contract)
    );
  };

  return (
    <div className="p-6 md:p-8 lg:p-12">
      <div className="border border-accent rounded-lg px-8 py-1 flex justify-center items-center mt-4 max-w-fit">
        <div className="flex gap-2 justify-center items-center">
          {account ? (
            <>
              <div className="h-4 w-4 bg-[#62bc78] rounded-full"></div>
              <div>Connected : {truncateAddress(account)}</div>
            </>
          ) : (
            <>
              <div className="h-4 w-4 bg-[#f7ce46] rounded-full"></div>
              <div>Disconnected</div>
            </>
          )}
        </div>
      </div>
      <div className="p-8">
        {nfts ? (
          <NFTCollection nfts={nfts} />
        ) : (
          <DisconnectedState connectWallet={connectWallet} />
        )}
      </div>
    </div>
  );
}
