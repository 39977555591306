import html2canvas from "html2canvas";

// @ts-expect-error types not available
import typographicBase from "typographic-base";

import logo from "../assets/img/logo.png";

export function missingClass(string, prefix) {
  if (!string) {
    return true;
  }

  const regex = new RegExp(` ?${prefix}`, "g");
  return string.match(regex) === null;
}

export function formatText(input) {
  if (!input) {
    return;
  }

  if (typeof input !== "string") {
    return input;
  }

  return typographicBase(input, { locale: "en-us" }).replace(
    /\s([^\s<]+)\s*$/g,
    "\u00A0$1"
  );
}

export function isNewArrival(date, daysOld = 30) {
  return (
    new Date(date).valueOf() >
    new Date().setDate(new Date().getDate() - daysOld).valueOf()
  );
}

export function isDiscounted(price, compareAtPrice) {
  if (compareAtPrice?.amount > price?.amount) {
    return true;
  }
  return false;
}

export function getExcerpt(text) {
  const regex = /<p.*>(.*?)<\/p>/;
  const correspondingText = regex.exec(text);
  return correspondingText ? correspondingText[1] : "";
}

function resolveToFromType(
  { customPrefixes, pathname, type } = {
    customPrefixes: {},
  }
) {
  if (!pathname || !type) return "";

  /*
        MenuItemType enum
        @see: https://shopify.dev/api/storefront/unstable/enums/MenuItemType
      */
  const defaultPrefixes = {
    BLOG: "blogs",
    COLLECTION: "collections",
    COLLECTIONS: "collections",
    FRONTPAGE: "frontpage",
    HTTP: "",
    PAGE: "pages",
    CATALOG: "collections/all",
    PRODUCT: "products",
    SEARCH: "search",
    SHOP_POLICY: "policies",
  };

  const pathParts = pathname.split("/");
  const handle = pathParts.pop() || "";
  const routePrefix = {
    ...defaultPrefixes,
    ...customPrefixes,
  };

  switch (true) {
    // special cases
    case type === "FRONTPAGE":
      return "/";

    case type === "ARTICLE": {
      const blogHandle = pathParts.pop();
      return routePrefix.BLOG
        ? `/${routePrefix.BLOG}/${blogHandle}/${handle}/`
        : `/${blogHandle}/${handle}/`;
    }

    case type === "COLLECTIONS":
      return `/${routePrefix.COLLECTIONS}`;

    case type === "SEARCH":
      return `/${routePrefix.SEARCH}`;

    case type === "CATALOG":
      return `/${routePrefix.CATALOG}`;

    // common cases: BLOG, PAGE, COLLECTION, PRODUCT, SHOP_POLICY, HTTP
    default:
      return routePrefix[type]
        ? `/${routePrefix[type]}/${handle}`
        : `/${handle}`;
  }
}

/*
  Parse each menu link and adding, isExternal, to and target
*/
function parseItem(customPrefixes = {}) {
  return function (item) {
    if (!item?.url || !item?.type) {
      // eslint-disable-next-line no-console
      console.warn("Invalid menu item.  Must include a url and type.");
      // @ts-ignore
      return;
    }

    // extract path from url because we don't need the origin on internal to attributes
    const { pathname } = new URL(item.url);

    /*
              Currently the MenuAPI only returns online store urls e.g — xyz.myshopify.com/..
              Note: update logic when API is updated to include the active qualified domain
            */
    const isInternalLink = /\.myshopify\.com/g.test(item.url);

    const parsedItem = isInternalLink
      ? // internal links
        {
          ...item,
          isExternal: false,
          target: "_self",
          to: resolveToFromType({ type: item.type, customPrefixes, pathname }),
        }
      : // external links
        {
          ...item,
          isExternal: true,
          target: "_blank",
          to: item.url,
        };

    return {
      ...parsedItem,
      items: item.items?.map(parseItem(customPrefixes)),
    };
  };
}

/*
  Recursively adds `to` and `target` attributes to links based on their url
  and resource type.
  It optionally overwrites url paths based on item.type
*/
export function parseMenu(menu, customPrefixes = {}) {
  if (!menu?.items) {
    // eslint-disable-next-line no-console
    console.warn("Invalid menu passed to parseMenu");
    // @ts-ignore
    return menu;
  }

  return {
    ...menu,
    items: menu.items.map(parseItem(customPrefixes)),
  };
}

export function getApiErrorMessage(field, data, errors) {
  if (errors?.length) return errors[0].message ?? errors[0];
  if (data?.[field]?.customerUserErrors?.length)
    return data[field].customerUserErrors[0].message;
  return null;
}

export function statusMessage(status) {
  const translations = {
    ATTEMPTED_DELIVERY: "Attempted delivery",
    CANCELED: "Canceled",
    CONFIRMED: "Confirmed",
    DELIVERED: "Delivered",
    FAILURE: "Failure",
    FULFILLED: "Fulfilled",
    IN_PROGRESS: "In Progress",
    IN_TRANSIT: "In transit",
    LABEL_PRINTED: "Label printed",
    LABEL_PURCHASED: "Label purchased",
    LABEL_VOIDED: "Label voided",
    MARKED_AS_FULFILLED: "Marked as fulfilled",
    NOT_DELIVERED: "Not delivered",
    ON_HOLD: "On Hold",
    OPEN: "Open",
    OUT_FOR_DELIVERY: "Out for delivery",
    PARTIALLY_FULFILLED: "Partially Fulfilled",
    PENDING_FULFILLMENT: "Pending",
    PICKED_UP: "Displayed as Picked up",
    READY_FOR_PICKUP: "Ready for pickup",
    RESTOCKED: "Restocked",
    SCHEDULED: "Scheduled",
    SUBMITTED: "Submitted",
    UNFULFILLED: "Unfulfilled",
  };
  try {
    return translations?.[status];
  } catch (error) {
    return status;
  }
}

export function emailValidation(email) {
  if (email.validity.valid) return null;

  return email.validity.valueMissing
    ? "Please enter an email"
    : "Please enter a valid email";
}

export function passwordValidation(password) {
  if (password.validity.valid) return null;

  if (password.validity.valueMissing) {
    return "Please enter a password";
  }

  return "Password must be at least 6 characters";
}

export function truncateAddress(address) {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
}

/** WXW FUNCTIONS!!!
 * USAGE:
 * getNFTComponentURLs:     returns an array of image URLs that correspond with the array of attributes you give it
 * createNFTImageStack:     takes an array of attributes and creates img elements for each attribute, in order, in the element specified
 * loadImage:               just a helper function used to wait for images to load fully before proceeding
 * downloadNFT:             "all in one" function that takes care of downloading an image for a specified array of attributes
 */

/** getNFTComponentURLs
 * Takes an array of attributes and returns an array of image URLs that correspond to them.
 * WARNING - image URLs may not necessarily correspond to images on the server, this does not check that they exist
 * @param {array}   attributes  - The attribute array (laid out such that attributes = [ {attributeName: attributeValue}, ... ])
 * @return {array}              - Returns an array of image URLs
 */
export function getNFTComponentURLs(attributes, showBackground = true) {
  var componentURLs = [];

  //if there's a faction, it's season 2
  var scpImageFolder = "https://wxw.s3.us-east-2.amazonaws.com/";
  if (attributes[0].key == "Faction") scpImageFolder += "wxw-s2/";
  else scpImageFolder += "wxw-s1/";

  attributes.forEach((attribute) => {
    let type = attribute.key.replace("/", "+"); //can't have a forward slash for obvious reasons so we sub in a +! (this is for "Hair/Helmets", etc)
    if (type == "Background" && !showBackground) {
      return;
    }

    let value = attribute.value;

    //for factions, this is S2 specific and determines which Season 2 folder to pull graphics from, also it always comes first
    if (type == "Faction") {
      scpImageFolder += `${value.toLowerCase()}/`; //we alter the host URL to have the faction at the end
      return;
    }

    //adds a URL to the component array in this format: "(our image host/)(attribute type)/(attribute value).png"
    componentURLs.push(
      `${scpImageFolder}${encodeURIComponent(type)}/${encodeURIComponent(
        value
      )}.png`
    );
  });

  return componentURLs;
}

/** createNFTImageStack
 * Takes an array of NFT attributes and creates a stack of images that correspond with each one, adding them to the specified element
 * Clears out the given elements if there is any content in them
 * @param {array}       attributes      - The attribute array (laid out such that attributes = [ {attributeName: attributeValue}, ... ])
 * @param {string}      addToElements   - Document query that creates the stack
 * @param {boolean}     skipCache       - specifies whether the stack should skip cache (crucial for downloading - should be true if used for html2canvas)
 * @return {Promise}                    - Returns a promise that is settled when images have finished loading
 */
export async function createNFTImageStack(
  attributes,
  addToElements,
  skipCache = false
) {
  //gets array of image URLs
  let components = getNFTComponentURLs(attributes);

  //grabs and clears out any elements
  let elements = document.querySelectorAll(addToElements);
  if (elements.length == 0) throw `No elements found - ${addToElements}`;
  elements.forEach((el) => (el.innerHTML = ""));

  //waits for all images to load, then adds them in order to each element specified
  await Promise.allSettled(components.map(loadImage)).then((images) => {
    images.forEach((image) => {
      if (typeof image.value != "undefined") {
        elements.forEach((el) => {
          el.insertAdjacentHTML(
            "beforeend",
            `<img src="${image.value.src}${
              skipCache ? `?${Date.now()}` : ``
            }" crossorigin="anonymous">`
          );
        });
      }
    });
  });
}

/** loadImage
 * Takes an image URL and waits for it to load (or fail)
 * Used in createNFTImageStack
 * @param {string}      src - The image URL to use
 * @return {Promise}    - Returns a promise that resolves to an HTML Image object (NOT an html element)
 */
export function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
}

/** downloadNFT
 * Takes an array of attributes and generates a WXW NFT image, then downloads it as a file.
 * @param {Object} settings - destructured object that should contain the below:
 * @param {array}    settings.attributes     - an array of NFT metadata attributes (laid out such that attributes = [ {attributeName: attributeValue}, ... ])
 * @param {number}   settings.resolution     - a number between 1 and 3000, specifies the width/height resolution it should be rendered at (ALWAYS square)
 * @param {number}   settings.tokenID        - a number that specifies the token ID being generated (used for filename)
 * @param {boolean}  settings.showBackground - a boolean that specifies whether the first layer should be shown or not
 * @returns {Promise}                           - this promise resolves when the image has been successfully downloaded, or the process failed
 */
export async function downloadNFT({
  attributes,
  resolution = 3000,
  tokenID = 0,
  showBackground = true,
}) {
  if (resolution == 0 || resolution > 3000)
    throw `Resolution must be between 1 and 3000 (got ${resolution})`;

  /* creates a hidden printing dimension within the page if it hasn't been created already */
  var printer = document.querySelector("#printer");
  var loadmsg = document.querySelector("#printloader span");
  if (printer) printer.innerHTML = ""; //just clears it out if it exists
  else {
    //otherwise, creates it
    printer = document.createElement("div");
    printer.id = "printer";
    document.body.insertAdjacentElement("beforeend", printer);

    //also adds a loading display
    let loader = document.createElement("div");
    loader.id = "printloader";
    loader.innerHTML = `
      <div class="img-anim"><img src="/logo.png"></div>
      <span></span>
    `;
    document.body.insertAdjacentElement("beforeend", loader);
    loadmsg = document.querySelector("#printloader span");
  }

  //used to control loading, etc
  document.body.classList.add("downloadingNFT");
  loadmsg.innerHTML = "Downloading attributes...";

  /* creates the div for what will be printed*/
  let printStack = document.createElement("div");
  printStack.classList.add("imagestack");
  printStack.style.cssText = `
    width: ${resolution}px;
    height: ${resolution}px;
   `;
  printer.insertAdjacentElement("beforeend", printStack);

  /* assembles image and waits for images to resolve */
  await createNFTImageStack(attributes, "#printer .imagestack", true);
  if (!showBackground) printStack.querySelector("img:first-child").remove(); //if no bg, remove the first image - it's always the bg

  loadmsg.innerHTML = "Assembling art...";
  /* prints with HTML2Canvas */
  let canvas = await html2canvas(printStack, {
    backgroundColor: null,
    allowTaint: true,
    useCORS: true,
    windowWidth: resolution,
    windowHeight: resolution,
  });

  /* downloads via link element */
  const download = document.createElement("a");
  download.href = canvas.toDataURL();
  //structures like so: WXW-Art(-tokenID if not 0)(-transparent if no BG).png
  download.download = `WXW-Art${tokenID ? `-${tokenID}` : ""}${
    showBackground ? "" : "-transparent"
  }.png`;
  download.click();

  loadmsg.innerHTML = "Success!";
  setTimeout(() => document.body.classList.remove("downloadingNFT"), 1000);
}
