import ContentLoader from "react-content-loader";

export function NFTPlaceholderCard(props) {
  return (
    <ContentLoader
      uniqueKey="nft-card-placeholder"
      speed={3}
      interval={0.1}
      width={300}
      height={470}
      viewBox="0 0 300 470"
      backgroundColor="#D3D3D3"
      foregroundColor="#D3D3D3"
      foregroundOpacity={0.5}
      {...props}
    >
      <rect x="0" y="0" rx="0" ry="0" width="2" height="470" />
      <rect x="298" y="0" rx="0" ry="0" width="2" height="470" />
      <rect x="0" y="0" rx="0" ry="0" width="470" height="2" />
      <rect x="0" y="468" rx="0" ry="0" width="300" height="2" />
      <circle cx="150" cy="150" r="125" />
      <rect x="75" y="350" rx="0" ry="0" width="160" height="9" />
      <rect x="110" y="375" rx="0" ry="0" width="92" height="9" />
    </ContentLoader>
  );
}
