import {
  Section,
  IconInstagram,
  IconFacebook,
  IconTwitter,
  IconDiscord,
  IconYoutube,
  IconTiktok,
} from "../../components";

/**
 * A server component that specifies the content of the footer on the website
 */
export function Footer({ menu }) {
  const menuItems = menu.map((item) => (
    <a key={item.id} href={item.to}>
      <div className="flex gap-2 items-center p-4">{item.title}</div>
    </a>
  ));
  return (
    <Section
      divider="none"
      as="footer"
      role="contentinfo"
      className="flex flex-col justify-center items-center w-full bg-dark text-light"
    >
      <div className={`flex gap-8 flex-wrap items-center justify-center`}>
        <a
          className="rounded-full text-lg p-3 border-2 border-light footersocial"
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/WomenandWeapons"
        >
          {" "}
          <IconTwitter />{" "}
        </a>
        <a
          className="rounded-full text-lg p-3 border-2 border-light footersocial"
          target="_blank"
          rel="noreferrer"
          href="https://discord.gg/womenandweapons"
        >
          {" "}
          <IconDiscord />{" "}
        </a>
        <a
          className="rounded-full text-lg p-3 border-2 border-light footersocial"
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/nftwomenandweapons/"
        >
          {" "}
          <IconInstagram />{" "}
        </a>
        <a
          className="rounded-full text-lg p-3 border-2 border-light footersocial"
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/nftwomenandweapons"
        >
          {" "}
          <IconFacebook />{" "}
        </a>
        <a
          className="rounded-full text-lg p-3 border-2 border-light footersocial"
          target="_blank"
          rel="noreferrer"
          href="https://www.tiktok.com/@womenandweapons"
        >
          {" "}
          <IconTiktok />{" "}
        </a>
        <a
          className="rounded-full text-lg p-3 border-2 border-light footersocial"
          target="_blank"
          rel="noreferrer"
          href="https://www.youtube.com/channel/UCCN4s8RYPMwMvbVmB6d2l2w"
        >
          {" "}
          <IconYoutube />{" "}
        </a>
      </div>

      <div className="flex justify-between flex-col md:flex-row">
        {menuItems}
      </div>

      <div className={`text-xs`}>
        © {new Date().getFullYear()} Women and Weapons
      </div>
    </Section>
  );
}
