import { NFTCard } from "../../components";

export function NFTCollection({ nfts }) {
  if (nfts.length == 0) {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="text-2xl">
          No Women and Weapon NFTs were found in your wallet!
        </div>
        <div className="text-lg">
          Head to{" "}
          <a
            className="underline text-accent"
            href="https://opensea.io/collection/womenandweapons"
          >
            OpenSea
          </a>{" "}
          and purchase one!
        </div>
      </div>
    );
  }

  const NFTCards = nfts.map((nft) => {
    return <NFTCard nft={nft} key={nft.id} />;
  });

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-8 max-w-screen-xl">
      {NFTCards}
    </div>
  );
}
