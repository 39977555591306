import { Header, Footer } from "../../components";

/**
 * A server component that defines a structure and organization of a page that can be used in different parts of the Hydrogen app
 */
export function Layout({ children }) {
  const headerMenu = [
    { title: "Home", to: "https://womenandweapons.io/", id: "Home" },
    {
      title: "About Us",
      to: "https://womenandweapons.io/pages/about-us-new",
      id: "About-Us",
    },
    {
      title: "Season One",
      to: "https://womenandweapons.io/pages/season-one",
      id: "Season-One",
    },
    {
      title: "Season Two",
      to: "https://womenandweapons.io/pages/season-two",
      id: "About-Us",
    },
    {
      title: "The Future",
      to: "https://womenandweapons.io/pages/the-future",
      id: "About-Us",
    },
    {
      title: "Marketplace",
      to: "https://womenandweapons.io/#",
      id: "Market-Place",
    },
    {
      title: "Provisions",
      to: "https://womenandweapons.io/collections/nft-holder-collection",
      id: "Provisions",
    },
  ];

  const footerMenu = [
    {
      title: "About Us",
      to: "https://womenandweapons.io/pages/about-us-new",
      id: "About-Us-Footer",
    },
    {
      title: "Social",
      to: "https://twitter.com/WomenandWeapons",
      id: "Social-Footer",
    },
    {
      title: "Community",
      to: "https://dsc.gg/womenandweapons",
      id: "Community",
    },
    {
      title: "Merch FAQ",
      to: "https://womenandweapons.io/pages/faq",
      id: "Merch-FAQ",
    },
  ];

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <div className="">
          <a href="#mainContent" className="sr-only">
            Skip to content
          </a>
        </div>
        <Header menu={headerMenu} />
        <main
          role="main"
          id="mainContent"
          className="flex-grow bg-light text-dark dark:bg-dark dark:text-light transition duration-300"
        >
          {children}
        </main>
      </div>
      <Footer menu={footerMenu} />
    </>
  );
}
